<template>
  <v-card class="pa-2">
    <div class="d-flex align-center primary lighten-4 rounded">
      <v-icon
        size="70"
        class="pa-4"
        color="primary lighten-1"
      >
        mdi-alert-outline
      </v-icon>
      <div>
        <span class="d-block font-weight-bold text-subtitle-1">
          {{ $t('charge.extra') }}
        </span>
        <span class="d-block">
          {{ $tc('charge.grantExtra', 1) }}
          <br />
          {{ $tc('charge.grantExtra', 0) }}
          <a
            target="_blank"
            href="https://nubowms.pl/cennik/"
          >
            {{ $t('charge.nuboPriceList') }}
          </a>
        </span>
      </div>
    </div>
    <div class="pt-2 d-flex justify-end">
      <v-btn
        color="primary lighten-1"
        elevation="0"
        class="mr-2"
        outlined
        @click="$emit('@close')"
      >
        {{ $t('actions.cancel') }}
      </v-btn>

      <v-btn
        color="primary lighten-1"
        elevation="0"
        style="color: white; font-weight: normal"
        @click="$emit('@add')"
      >
        {{ $t('actions.add') }}
      </v-btn>
    </div>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
